<template>
  <teaching-package-detail-page
    class="views_shopping_mail_pages_teaching_package_detail bbox mt-10"
    :package_id="Number($route.params.package_id)"
  ></teaching-package-detail-page>
</template>

<script>
import TeachingPackageDetailPage from '@/views/ShoppingMail/TeachingPackage/Details.vue';
export default {
  components: {
    TeachingPackageDetailPage
  },
}
</script>

<style lang="scss" scoped>
.views_shopping_mail_pages_teaching_package_detail{
  width: 100%;
  padding: {
    left: 40px;
    right: 40px;
  }
}
</style>
