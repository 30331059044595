var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("teaching-package-detail-page", {
    staticClass: "views_shopping_mail_pages_teaching_package_detail bbox mt-10",
    attrs: { package_id: Number(_vm.$route.params.package_id) },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }